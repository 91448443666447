import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import * as fromProfile from "@app/profile/store";
import { select, Store } from "@ngrx/store";
import { map, Observable, tap } from "rxjs";
import { DiaryDetailsService } from "../services/diary-details.service";

@Injectable()
export class ProfileAuthGuard implements CanActivate {
  constructor(
    private store: Store<fromProfile.State>,
    private router: Router,
    private diaryDetailsService: DiaryDetailsService,
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(fromProfile.selectAuthToken()),
      map((token) => !!token),
      tap((token) => {
        const hashId = this.diaryDetailsService.getHashId();
        const only = this.diaryDetailsService.getOnlyKey();

        if (!token) {
          if (hashId) {
            this.router.navigate(["/profile/questions-greeting"], {
              queryParams: {
                hash_id: hashId,
                only,
              },
            });
          } else {
            this.router.navigate(["/auth/login"]);
          }
        }
      }),
    );
  }
}
